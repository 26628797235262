/* ======================================================================== */
/* 50. animateLines */
/* ======================================================================== */
function animateLines($target, duration = 1.2, stagger = 0.02, offset = '-=0.6') {

	var
		tl = new TimelineMax(),
		$headlineProperty = $target.find('.figure-property__headline'),
		$words = $target.find('.split-text__word');

	if ($headlineProperty.length) {

		tl
			.to($headlineProperty, duration, {
				ease: Expo.easeOut,
				scaleX: 1
			}, '0');

	}

	if ($words.length) {

		tl
			.staggerTo($words, duration, {
				y: '0%',
				ease: Power3.easeOut,
				autoAlpha: 1,
			}, stagger, offset);

	};

	return tl;

}
