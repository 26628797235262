/* ======================================================================== */
/* 39. sectionNavProjects */
/* ======================================================================== */
var SectionNavProjects = function ($scope) {

	var $target = $scope.find('.section-nav-projects');

	if (!$target.length) {

		return;

	}

	var $backgrounds = $target.find('.section-nav-projects__background');

	$document
		.on('mouseenter touchstart', '.section-nav-projects__inner[data-post-id]', function () {
			var
				postId = $(this).data('post-id'),
				$targetBackground = $backgrounds.filter('[data-background-for="' + postId + '"]');

			if (!$targetBackground.length) {
				return;
			}

			$backgrounds.filter('active').removeClass('active');
			$targetBackground.addClass('active');

		})
		.on('mouseleave touchend', '.section-nav-projects__inner[data-post-id]', function () {

			$backgrounds.filter('.active').removeClass('active');

		})
		.on('click', 'a[data-post-id]', function () {

			var
				postId = $(this).data('post-id'),
				$targetBackground = $backgrounds.filter('[data-background-for="' + postId + '"]'),
				$targetBackgroundGlobal = window.$backgrounds.filter('[data-background-for="' + postId + '"]');

			if (!$targetBackground.length) {
				return;
			}

			$backgrounds.filter('active').removeClass('active');
			$targetBackgroundGlobal.addClass('selected');
			$targetBackground.addClass('selected');

		});

}
